import { useParams } from "react-router-dom"
import { entries } from '../data';
import parse  from 'html-react-parser';
import EmbedVideo from './embedVideo';

export default function Documental() {
    const { id } = useParams();
    const str = entries.filter((entrie) => {
        return entrie.link === id
    });
    return (
        <div className="">
            <img className=" h-48 w-auto" src={"/images/" + str[0]["mainImage"]} alt='' />
            <h1 className="titulo-de-pagina">{str[0]["title"]}</h1>
            <div>
                <EmbedVideo embedId={str[0]["video-id"]} />
                <div className="pagina-documental">{parse(str[0]["content"])}</div>
            </div>
        </div>
    );
  }