export const entries2 = [
    {
        "ano": 2008,
        titulo: "el disc de newton (el disco de newton)",
        desc: "producción en l’àmbit de la interculturalidad, a partir de la performance sobre el cuento “el disc de newton” (“el disco de newton”), de miquel ribas. para el ayuntamiento de barcelona.",
    }, {
        "ano": 2006,
        "titulo": "historias de un locutorio",
        desc: "los locutorios son el punto de encuentro entre los inmigrantes que han llegado al barrio de la macarena de sevilla y sus seres queridos, que siguen “allá”. para el programa “linea 900” de televisión española.",
    }, {
        "ano": 2005,
        "titulo": "aún quedan flores en formentera",
        desc: "dos hombres y dos mujeres que llegaron a formentera hace 40 años, cuando lo hicieron otros muchos hippies, y que se han quedado a vivir allí. para el programa “linea 900” de televisión española.",
    }, {
        "ano": 2005,
        "titulo": "crecer a cualquier precio",
        "desc": "carreteras, vías férreas, un puerto, un aeropuerto y otras infraestructuras, destrozan y hacen agonizar lo que fue un vergel alrededor de la desembocadura del río llobregat de barcelona. para el programa “línea 900” de televisión española.",
    }, {
        "ano": 2005,
        "titulo": "10 anys",
        "desc": "10 años de la muestra de asociaciones de barcelona. para el ayuntamiento de barcelona.",
    }, {
        "ano": 2004,
        "titulo": "un quiebro me da la vida",
        "desc": "la vida nos da un quiebro y nos encontramos viviendo en la calle. para el programa “línea 900” de televisión española.",
    }, {
        "ano": 1997,
        "titulo": "esos garífunas a los que tanto gusta bailar",
        "desc": "comunidades negras del caribe continental. documental fuera de catálogo.",
    }, {
        "ano": 2008,
        "titulo": "bagdad, de wright a venturi",
        "desc": "serie de ocho capítulos para la exposición “bagdad, de wright a venturi” , que complementa la muestra de maquetas de edificios y proyectos realizados por grandes arquitectos internacionales en bagdad.",
    }, {
        "ano": 2006,
        "titulo": "entre el cel i la terra",
        "desc": "“entre el cel i la terra” (“entre el cielo y la tierra”) y “temps de caló” (“tiempo de caló”). el arte de los gitanos en catalunya. una producción para la fira de manresa (barcelona).",
    }, {
        "ano": 2005,
        "titulo": "malaria, la vacuna es posible",
        "desc": "investigación de la vacuna de la malaria en mozambique por un equipo dirigido por el doctor pedro alonso. para el programa “línea 900” de televisión española.",
    }, {
        "ano": 2005,
        "titulo": "el mundo en mi barrio",
        "desc": "el 50 % de la población del barrio de el raval (barcelona) ha nacido fuera de españa. para el programa “línea 900” de televisión española.",
    }, {
        "ano": 2004,
        "titulo": "barcelona 2050",
        "desc": "aproximación a la barcelona del futuro. para el forum de las culturas.",
    }, {
        "ano": 2004,
        "titulo": "¿quién me echa un capote?",
        "desc": "dificultades de los maletillas del siglo XXI. para el el programa “línea 900” de televisión española.",
    }, {
        "ano": 1996,
        "titulo": "segundo montes, una utopía",
        "desc": "comunidad de refugiados salvadoreños que regresan a su país después de los acuerdos de paz. documental fuera de catálogo."
    }
]