import React from 'react';
import { useLayoutEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import Documental from './components/documental';
import OtrosDocumentales from './components/otrosDocumentales';
import Nosotros from './components/nosotros';
import Documentales from './components/documentales';
import Header from './components/header';
import Footer from './components/footer';
import Inicio from './components/inicio';
import './styles.css';

// scroll up after each page
const Wrapper = ({children}) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
} 

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <div className="flex flex-row m-10 px-48">
        <div className='basis-1/4'>
          <Header />
        </div>
        <div className='basis-3/4 container mx-auto px-4'>
        <Wrapper>
          <Routes>
            <Route path="/" element={<Inicio />} />
            <Route path="/documentales" element={<Documentales />} />
            <Route path="/otros-documentales" element={<OtrosDocumentales />} />
            <Route path="/nosotros" element={<Nosotros />} />
            <Route path={'documentales/:id'} element={<Documental />} />
          </Routes>
          </Wrapper>
        </div>
      </div>
      <div className=''>
        <Footer />
      </div>
    </BrowserRouter>
  </React.StrictMode>
);