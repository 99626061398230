import { entries } from '../data';
import ReadMoreButton from './readMoreButton';

export default function Documentales(props) {
    return (
        <div className='mt=10 pagina-documentales'>
            <h1 className="titulo-de-pagina">documentales</h1>
          {entries.map((data, key) => {
            return(
                <div key={key} className="mt-10">
                    <img src={"images/" + data.mainImage} alt='' />
                    <h3 className='text-3xl font-bold mb-8'>{data.title}</h3>
                    {data.teaser}
                    <ReadMoreButton url={data.link} />
                </div>
            );
        })}
        </div>
      );
  }
  