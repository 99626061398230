import { entries2 } from '../data2';

export default function OtrosDocumentales() {
    return (
        <div>
            <h1 className="titulo-de-pagina">otros documentales</h1>
            <div className='grid grid-cols-2 gap-4'>
            {entries2.map((data, key) => {
                return(
                    <div key={key}>
                        <div>
                            <h3 className='font-bold'>{data.titulo}</h3>
                            {data.desc}
                        </div>
                    </div>
                );
            })}
        </div>
        </div>
      );
  }
