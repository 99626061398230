import React from "react";
import { Link } from "react-router-dom";

let mainSiteName = "Sensemaya";
export default function Header(props) {
  if (props.title !== undefined) {
    mainSiteName = props.title;
  }
    return (
      <div className="header">
        <div>
        <Link to={"/"}><h1 className="text-3xl font-bold mt-0 mb-6 text-center">{mainSiteName.toUpperCase()}</h1></Link>
          <Logo />
        </div>
        <div>
          <MainMenu />
        </div>
      </div>
    );
  }
  function Logo(){
    return(
      <div className="logo">
        <Link to={"/"}><img src="/images/cropped-palm-192x192.png" alt="Logo de Sensemaya" className="h-32 w-auto mx-auto" /></Link>
      </div>
    )
  }
  function MainMenu() {
    return (
      <div className="main-nav">
            <nav>
              <ul>
                <li className="">
                  <Link to="/" className="menu_item">Inicio</Link>
                </li>
                <li className="">
                  <Link to="/nosotros" className="menu_item">nosotros</Link>
                </li>
                <li className="">
                  <Link to="/documentales" className="menu_item">documentales</Link>
                </li>
                <li className="">
                  <Link to="/otros-documentales" className="menu_item">otros documentales</Link>
                </li>
              </ul>
            </nav>
      </div>
    );
  }
