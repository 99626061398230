import { Link } from "react-router-dom";

export default function Nosotros() {
    return (
        <div className="pagina-nosotros">
            <h1 className="titulo-de-pagina">nosotros</h1>
            <p>somos una productora de documentales. iniciamos nuestra actividad en 1995 con el documental “siete sueños de paz”, siete salvadoreños que afrontan el futuro, una vez firmados los acuerdos de paz.<br></br>  nos interesa ese amplio abanico que va desde eso que llaman “temas sociales” a eso que llaman “temas-culturales-en-sentido-amplio”. de hecho, es el campo en el que somos más felices trabajando. por las mismas razones, hemos elegido el territorio de américa central como zona preferencial de actuación, aunque también hemos realizado algunas producciones en áfrica y europa.</p>
            <img src="/images/granada.jpg" alt=""></img>
            <p>desde que iniciamos nuestra actividad hemos tenido una gran preocupación por el modo de afrontar los temas. compartimos con octavio paz aquello de que el verdadero compromiso de quien desarrolla un trabajo creativo “no está tanto en lo que dice, como en la forma de afrontar el problema del lenguaje”. los temas son los que son y, en todo caso, nuestro acierto o desacierto está en la elección. pero donde hacemos nuestra verdadera aportación –también con acierto o desacierto-  es en el modo en  el que contamos esos temas.</p>
            <img src="/images/canada.jpg" alt=""></img>
            <p>nos gustan las distancias cortas, las historias particulares a partir de las cuales contar temas universales. sugerir y no sentenciar. ver que pasa después de la noticia. escuchar. mirar (somos profundamente curiosos). sentarse y conversar.  reflexionar sin aburrir (eso no nos lo perdonaríamos ni nosotros mismos). y hacerlo documental.<br></br>nuestro objetivo es que, una vez visto uno de nuestros documentales, la gente se de un codazo y siga hablando del tema. y si lo ven solos, que inicien un trepidante soliloquio.</p>
        </div>
      );
  }
