import { entries } from '../data';
import ReadMoreButton from './readMoreButton';

export default function Inicio() {
    return (
        <div className='mt=10 pagina-inicio'>
            <h1 className="titulo-de-pagina">inicio</h1>
                <section className="grid max-w-screen-xl px-4 mx-auto">
                    <div className="mr-auto place-self-center lg:col-span-7">
                        <h1 className="max-w-2xl mb-2 text-lg text-gray-700 font-extrabold tracking-tight leading-none">somos una productora de documentales</h1>
                        <p className="max-w-2xl font-light text-gray-700 md:text-lg lg:text-xl dark:text-gray-400">nos interesa ese amplio abanico que va desde eso que llaman “temas sociales” a eso que llaman “temas-culturales-en-sentido-amplio”. de hecho, es el campo en el que somos más felices trabajando.</p>
                    </div>
                </section>
            <div className='grid grid-cols-2 gap-4'>
          {entries.map((data, key) => {
            return(
                <div key={key} className="mt-10">
                    <img className=" h-48 w-auto" src={"images/" + data.mainImage} alt='' />
                    <h3 className='text-2xl font-bold mb-8'>{data.title}</h3>
                    <ReadMoreButton url={data.link} />
                </div>
            );
        })}
        </div>
        </div>
      );
  }