export const entries = [
  {
    "title": "tan lejos y tan cerca, bagdad",
    "mainImage": "01i.jpg",
    "link": "tan-lejos-y-tan-cerca-bagdad",
    "pubDate": "Wed, 10 Aug 2011 16:07:51 +0000",
    "creator": "admin",
    "teaser": "las guerras que ha sufrido irak han obligado a muchos de sus ciudadanos a emigrar y establecerse en una tierra que les acogiera.bagdad, la capital del país, es una ciudad con una intensa y larga historia. sus ciudadanos se sienten estrechamente ligados a ella. los que sobreviven y resisten allí y los que un día tuvieron que dejarla.la diáspora de ciudadanos bagdadíes se ha extendido por europa y oriente medio.",
    "content": `<p>las guerras que ha sufrido irak han obligado a muchos de sus ciudadanos a emigrar y establecerse en una tierra que les acogiera.<br>bagdad, la capital del país, es una ciudad con una intensa y larga historia. sus ciudadanos se sienten estrechamente ligados a ella. los que sobreviven y resisten allí y los que un día tuvieron que dejarla.<br>la diáspora de ciudadanos bagdadíes se ha extendido por europa y oriente medio. hoy viven en paz, pero la mayoría de ellos no ha dejado de mirar atrás en ningún momento, con el deseo de volver lo antes posible. la nostalgia no les ha abandonado desde el día en que se fueron, quizá porque saben que la vuelta será difícil.</p>
    <img src="/images/i02.jpg" alt="" class="wp-image-115"/>
    <p>hussain al-saidnaji llegó a barcelona con su hijo hadi, gracias a varias organizaciones no gubernamentales, que les ayudaron a hacer el viaje. hadi estaba enfermo del corazón y en irak no le daban ningún tipo de esperanzas.<br>durante casi dos años vivieron solos hussain y hadi en barcelona, mientras el resto de su familia, la esposa y otros dos hijos de hussain, tuvieron que permanecer en bagdad. su tercer hijo nació dos días depuse que dejaran irak y no le conocieron hasta que, por fin, pudieron reunirse los cinco en barcelona.</p>
    <img src="/images/i03.jpg" alt="" class="wp-image-116"/>
    <p>hadi necesitaría un doble transplante de corazón y de pulmón, incluso llegó a estar en la lista de espera, pero su salud, dentro de la gravedad, se ha estabilizado y, mientras siga así, los médicos prefieren no operarle. hasta la fecha, en españa sólo se ha hecho una operación de doble transplante de pulmón y corazón a un niño, y la hizo el quipo médico que trata a hadi.<br>la historia de este padre y este hijo, así como la de su familia, es como tantas otras. todas particulares, todas tristes. pero con un sentimiento común que hadi manifiesta con la claridad y la inocencia que le corresponde cuando dice que aquí se está muy bien “pero es que yo quiero ir a irak”</p>
    <p><strong>director:</strong><br>lluís crous<br><strong>cámara:</strong><br>ernesto villalobos<br><strong>editora:</strong><br>ariadna andreu<br><strong>dirección ejecutiva tvc</strong><br>joan salvat<br><strong>producción ejecutiva tvc</strong><br>muntsa tarrés<br><strong>duración</strong>: 52'<br>una producción sensemayá, en co-producción con TV3, con el apoyo de MEDIA EU</p>`,
    "video": "https://www.youtube.com/watch?v=Qnl891iDEC4",
    "video-id": "Qnl891iDEC4",
    "post_id": 5,
    "post_date": "2011-08-10 16:07:51"
  },
  {
    "title": "linchamiento (el crimen colectivo)",
    "mainImage": "01.jpg",
    "link": "linchamiento-el-crimen-colectivo",
    "pubDate": "Wed, 10 Aug 2011 16:10:44 +0000",
    "creator": "admin",
    "teaser": "acabó la guerra y se firmó la paz en guatemala. desde entonces ha aumentado un fenómeno dramático en todo el país: los linchamientos. una turba enloquecida persigue a un presunto delincuente, lo detiene y lo mata.“linchamiento (el crimen colectivo)” hace un recorrido, pueblo a pueblo, para saber cómo era la vida en les zonas indígenas de guatemala antes de la guerra, cómo se vivió el conflicto armado y qué atrocidades",
    "content": `<p>acabó la guerra y se firmó la paz en guatemala. desde entonces ha aumentado un fenómeno dramático en todo el país: los linchamientos. una turba enloquecida persigue a un presunto delincuente, lo detiene y lo mata.<br>“linchamiento (el crimen colectivo)” hace un recorrido, pueblo a pueblo, para saber cómo era la vida en les zonas indígenas de guatemala antes de la guerra, cómo se vivió el conflicto armado y qué atrocidades –entre otras, los linchamientos- cometió el ejército en la población.<br>también, pueblo a pueblo, muestra cómo se llevan a cabo los linchamientos, y lo hace con las autoridades de les comunidades en las que se han producido y de profesionales que, desde diferentes sectores, intentan entender el problema.</p>
    <img src="/images/l02.jpg" alt="" class="wp-image-112"/>
    <p>“linchamiento (el crimen colectivo)” se pregunta cómo se puede restablecer la convivencia que había antes de la guerra en las comunidades indígenas mayas. no será un camino fácil, ni corto.</p>
    <img src="/images/l04.jpg" alt="" class="wp-image-113"/>
    <p>todos apuntan a la corrupción que vive la justicia, que provoca impunidad, como uno de los problemas de donde surgen los linchamientos. pero, ante todo, se destaca la huella que dejó la guerra, en la que los militares de les reiteradas dictaduras impusieron modelos que nada tenían que ver con los valores de los indígenas mayas. aquellos modelos hoy todavía están presentes en la población, así como los grupos paramilitares que colaboraban con el ejército y que siguen armados y, ahora, sin trabajo.</p><p><strong>director:</strong><br>lluís crous<br><strong>cámara:</strong><br>ernesto villalobos<br><strong>editora:</strong><br>carmen g. martinez<br><strong>dirección ejecutiva tvc</strong><br>joan salvat<br><strong>producción ejecutiva tvc</strong><br>muntsa tarrés<br>duración: <strong>51'</strong><br>una producción sensemayá, con la colaboración de TV3</p>`,
    "video": "https://www.youtube.com/watch?v=NCo6rteW2ks",
    "video-id": "NCo6rteW2ks",
    "post_id": 8,
    "post_date": "2011-08-10 16:10:44"
  },
  {
    "title": "algo se mueve bajo los bananos",
    "mainImage": "00.jpg",
    "link": "algo-se-mueve-bajo-los-bananos",
    "pubDate": "Mon, 10 Aug 2009 16:33:25 +0000",
    "creator": "admin",
    "teaser": "las repúblicas centroamericanas recibieron el calificativo de bananeras desde que la united fruit company se estableciera en la zona. desde entonces, el sector bananero se ha desarrollado como un mundo cerrado y de muros infranqueables",
    "content": `<p>las repúblicas centroamericanas recibieron el calificativo de bananeras desde que la united fruit company se estableciera en la zona. desde entonces, el sector bananero se ha desarrollado como un mundo cerrado y de muros infranqueables.</p>
    <img src="/images/a01.jpg" alt="" class="wp-image-118"/>
    <p>“algo se mueve bajo los bananos” hace un seguimiento de un conflicto laboral en las bananeras de la población de morales, al norte de guatemala.<br>los hechos se iniciaron con el despido de 900 trabajadores de la multinacional bandegua/del monte.<br>el sindicato sitrabi convocó una manifestación frente a las oficinas de la empresa. la noche antes de la concentración, un grupo de asaltantes entró en la sede del sindicato y, a punta de pistola, retuvo a los miembros de la directiva sindical y les obligó a firmar su renuncia.<br>no sin dificultades, se acabó celebrando el juicio y, por primera vez en la historia de guatemala, se condenó a los responsables de un hecho de estas características. sin embargo, las sentencias fueron muy leves y los cinco dirigentes sindicales, que no dejaron de recibir amenazas, tuvieron que exiliarse fuera del país.</p>
    <img src="/images/03.jpg" alt="" class="wp-image-19"/>
    <p>han vuelto las amenazas. ahora a los miembros de la nueva ejecutiva.</p>
    <p><strong>director:</strong><br>lluís crous<br><strong>cámara:</strong><br>ernesto villalobos<br><strong>editora:</strong><br>carmen g. martinez<br><strong>duración</strong>: 52'<br>una producción sensemayá</p>`,
    "video": "https://www.youtube.com/watch?v=dDe5h2s3utA",
    "video-id": "dDe5h2s3utA",
    "post_id": 17,
    "post_date": "2009-08-10 16:33:25"
  },
  {
    "title": "lejos de la habana",
    "mainImage": "00h.jpg",
    "link": "lejos-de-la-habana",
    "pubDate": "Sun, 10 Aug 2008 16:38:03 +0000",
    "creator": "admin",
    "teaser": "“lejos de la habana” sigue durante seis años a un inmigrante cubano desde que llega a barcelona hasta que se establece en su nueva ciudad.nació dos años después del triunfo de la revolución. vivió inmerso en ella porqué así le tocó en suerte. a los cuarenta y dos años –y después de haberlo pensado a lo largo de toda su vida- decide abandonar la isla. lo hace “como turista”",
    "content": `<p>“lejos de la habana” sigue durante seis años a un inmigrante cubano desde que llega a barcelona hasta que se establece en su nueva ciudad.<br>nació dos años después del triunfo de la revolución. vivió inmerso en ella porqué así le tocó en suerte. a los cuarenta y dos años –y después de haberlo pensado a lo largo de toda su vida- decide abandonar la isla. lo hace “como turista”. se va presionado por una realidad que le impide ser él mismo.</p>
    <img src="/images/hh01.jpg" alt="" class="wp-image-120"/>
    <p>ricardo muro busca ser dueño de su propio destino y sueña con viajar. en barcelona encuentra una vida de luces y sombras.<br>su historia es el hilo conductor que permite comprender otras historias cubanas: una santera cubana que viene en busca de sus nietos, un beisbolista que entrena en las canchas barcelonesas, un bailarín profesional que se valió de su talento para salir del país o un locutor de radio que vino por amor.</p>
    <img src="/images/02h.jpg" alt="" class="wp-image-23"/>
    <p>un retrato de cubanos en barcelona. los que llenan los bares de salsa, bolero y son. los que sonríen al mal tiempo con buena cara. los que mantienen algunos valores de la revolución, pero que viven en una constante crítica al castrismo y que, como nuestro protagonista, piensan que ahora “bueno, a lo mejor todo cambia”. la habana en barcelona.</p>
    <p><strong>directora:</strong><br>aleyda valdez<br><strong>producción:</strong><br>lluís crous<br><strong>cámara:</strong><br>fabricio pérez<br><strong>editora:</strong><br>virginia garcía del pino<br><strong>duración</strong>: 50'<br>una producción sensemayá</p>`,
    "video": "https://www.youtube.com/watch?v=ojPUyyRvALs",
    "video-id": "ojPUyyRvALs",
    "post_id": 21,
    "post_date": "2008-08-10 16:38:03"
  },
  {
    "title": "en un rincón de áfrica",
    "mainImage": "00a.jpg",
    "link": "en-un-rincon-de-africa",
    "pubDate": "Sat, 11 Aug 2007 10:30:53 +0000",
    "creator": "admin",
    "teaser":"“en un rincón de áfrica” intenta descubrir las motivaciones de conciudadanos nuestros que un día decidieron dejarlo todo y marcharse a áfrica, como cooperantes. todos los protagonistas del documental han elegido mozambique, uno de los países que en los últimos años ha recibido un mayor número de cooperantes procedentes del primer mundo",
    "content": `<p>"en un rincón de áfrica” intenta descubrir las motivaciones de conciudadanos nuestros que un día decidieron dejarlo todo y marcharse a áfrica, como cooperantes.</p>
    <img src="/images/af02.jpg" alt="" class="wp-image-124"/>
    <p>todos los protagonistas del documental han elegido mozambique, uno de los países que en los últimos años ha recibido un mayor número de cooperantes procedentes del primer mundo. son profesionales de diferentes áreas que deciden desarrollar parte de su vida laboral en lugares donde les puedan necesitar. una vez allí, hacen de médicos en las áreas rurales de un país de diez millones de habitantes y en el que solo hay seiscientos médicos, ponen sus conocimientos profesionales como agrónomos para que las cosechas sean más abundantes, hacen de activistas en defensa del medio ambiente o de los derechos de las mujeres, ayudan a los maestros locales que trabajan con muy pocos recursos, investigan sobre el terrero, luchan contra el sida…</p>
    <img src="/images/af04.jpg" alt="" class="wp-image-125"/>
    <p>en las razones profesionales (“aquí me siento más útil que en barcelona”), personales (“la vida es muy corta y hay que vivirla intensamente”) y sociales (“trabajamos por ideales, porque pensamos que otro mundo es posible”) es donde se adentra “en un rincón de áfrica”.</p>
    <p><strong>director:</strong><br>lluís crous<br><strong>cámaras:</strong><br>lluís crous/ aleyda valdez<br><strong>editora:</strong><br>carmen g. martínez<br><strong>duración</strong>: 51'<br>una producción sensemayá</p>`,
    "video": "https://www.youtube.com/watch?v=cDJeU5LmY8Q",
    "video-id": "cDJeU5LmY8Q",
    "post_id": 25,
    "post_date": "2007-08-11 10:30:53"
  },
  {
    "title": "los ojos del sida",
    "mainImage": "s00.jpg",
    "link": "los-ojos-del-sida",
    "pubDate": "Sat, 11 Aug 2007 10:34:54 +0000",
    "creator": "admin",
    "teaser":"en el mundo hay más de 40 millones de personas contagiadas con el virus del sida. una cuarta parte de ellas viven en los países del áfrica austral.la exuberancia de los paisajes de botswana, namibia, zambia, malawi, mozambique y sudáfrica, recorridos en “los ojos del sida”, contrasta con la desesperanza de quienes deben enfrentarse cada día a la lucha cotidiana contra el sida.",
    "content": `<p>en el mundo hay más de 40 millones de personas contagiadas con el virus del sida. una cuarta parte de ellas viven en los países del áfrica austral.<br>la exuberancia de los paisajes de botswana, namibia, zambia, malawi, mozambique y sudáfrica, recorridos en “los ojos del sida”, contrasta con la desesperanza de quienes deben enfrentarse cada día a la lucha cotidiana contra el sida.</p>
    <img src="/images/si02.jpg" alt="" class="wp-image-122"/>
    <p>todos coinciden en afirmar que el rechazo social y la pobreza están en la base de la rápida expansión de la pandemia en sus territorios.<br>el documental se ha acercado, buscando las distancias cortas, a cuatro personas que padecen la enfermedad: doña julia, que quiso huir cuando le comunicaron que tenia sida; emmelina, que fue violada y contagiada con el virus en las calles de johannesburgo; alvaro, que quisiera volver a ser músico, actividad que tuvo que dejar por la enfermedad; y ernesto, a quien abandonó su esposa cuando supo que su marido estaba contagiado con el virus del vih.</p>
    <img src="/images/s03.jpg" alt="" class="wp-image-31"/>
    <p>cuatro miradas distintas, representativas de otras tantas miradas, deseosas de ver un nuevo horizonte y, a poder ser, alcanzarlo.</p>
    <p><strong>directores:</strong><br>lluís crous /<br>aleyda valdez<br><strong>duración</strong>: 52'<br>una producción sensemayá</p>`,
    "video": "https://www.youtube.com/watch?v=MY-VXpCjYYo",
    "video-id": "MY-VXpCjYYo",
    "post_id": 29,
    "post_date": "2007-08-11 10:34:54"
  },
  {
    "title": "saber quién echó fuego ahí",
    "mainImage": "g03.jpg",
    "link": "saber-quien-echo-fuego-ahi",
    "pubDate": "Thu, 11 Aug 2005 10:39:10 +0000",
    "creator": "admin",
    "teaser":"a las 11h del 31 de enero de 1980, un grupo de campesinos, apoyados por estudiantes, ocupó la embajada de españa en guatemala. querían denunciar públicamente las masacres que el ejército del general fernando romeo lucas garcía estaba llevando a cabo en el interior del país; especialmente en la zona del quiché. a las 13h30 la policía tomó los balcones y el techo del edificio de la embajada.",
    "content": `<p>a las 11h del 31 de enero de 1980, un grupo de campesinos, apoyados por estudiantes, ocupó la embajada de españa en guatemala. querían denunciar públicamente las masacres que el ejército del general fernando romeo lucas garcía estaba llevando a cabo en el interior del país; especialmente en la zona del quiché.</p>
    <img src="/images/g00.jpg" alt="" class="wp-image-34"/>
    <p>a las 13h30 la policía tomó los balcones y el techo del edificio de la embajada. a las 15 h la sede ardía, sin que se dejara actuar a nadie para apagar el fuego. murieron calcinadas 37 personas.<br>tres décadas después, retomamos el tema en este documental, que hemos llamado "saber quién echó fuego ahí", nudo de los hechos y frase que pronunció el campesino gregorio yujá en el hospital, donde era atendido por sus quemaduras, la madrugada antes de ser secuestrado y asesinado.<br>nuestro recorrido se inicia en las montañas del quiché, al norte de guatemala. buscamos a los familiares de los campesinos que allí vivían y que murieron en la embajada.</p>
    <img src="/images/g03.jpg" alt="" class="wp-image-35"/>
    <p>a lo largo del documental se van entrecruzando pequeñas grandes historias, vividas por unos y otros en primera persona. si alguno de los testimonios miente, lo hace a cara descubierta y en nombre propio. buscamos luces en unos hechos llenos de sombras.</p>
    <p><strong>director:</strong><br>lluís crous<br><strong>cámara:</strong><br>ernesto villalobos<br><strong>editora:</strong><br>aleyda valdez<br><strong>duración</strong>: 51'<br>una producción sensemayá</p>`,
    "video": "https://www.youtube.com/watch?v=H8klwRcpTH0",
    "video-id": "H8klwRcpTH0",
    "post_id": 33,
    "post_date": "2005-08-11 10:39:10"
  },
  {
    "title": "bienvenidos a intipucá city",
    "mainImage": "es00.jpg",
    "link": "bienvenidos-a-intipuca-city",
    "pubDate": "Wed, 11 Aug 2004 10:44:43 +0000",
    "creator": "admin",
    "teaser":"intipucá era un pequeño pueblo de cañas y barro en el interior de el salvador. en la década de los sesenta, uno de sus vecinos (entrevistado, entre otros, en “bienvenidos a intipucá city”) emigró hacia el norte, a los estados unidos. fue el primero. un aventurero.",
    "content": `<p>intipucá era un pequeño pueblo de cañas y barro en el interior de el salvador. en la década de los sesenta, uno de sus vecinos (entrevistado, entre otros, en “bienvenidos a intipucá city”) emigró hacia el norte, a los estados unidos. fue el primero. un aventurero. hoy, más de la mitad de la población de intipucá vive en los “yunai” y mantiene a la otra mitad -la que se ha quedado en el lugar– con las “remesas”, en dólares, que manda.<br>la llegada del dinero de los emigrantes salvadoreños, residentes en los estados unidos, se ha convertido en la principal fuente de ingresos del país. supera incluso los beneficios que da el café, el principal producto de cuantos se cultivan en el salvador.</p>
    <p>son las fiestas patronales de intipucá city y los intipuqueños de estados unidos regresan por unos días a su “ciudad” natal. lo hacen llenos de nostalgia y de dólares. traen una nueva “way of life”, que ya no tiene cabida en intipucá. el documental mezcla bailes sabrosones y rock, nuevos ricos y espaldas mojadas. abuelos emigrantes, que han regresado. padres emigrantes, que no saben si regresarán. hijos de emigrantes, que ni se plantean regresar.</p>
    <img src="/images/es03.jpg" alt="" class="wp-image-39"/>
    <p><strong>director:</strong><br>lluís crous<br><strong>cámara:</strong><br>ernesto villalobos<br><strong>editor:</strong><br>oriol forn<br><strong>ayudante de dirección:</strong><br>aleyda valdez<br><strong>duración</strong>: 51'<br>una producción sensemayá</p>`,
    "video": "https://www.youtube.com/watch?v=0rELnb-oFAM",
    "video-id": "0rELnb-oFAM",
    "post_id": 37,
    "post_date": "2004-08-11 10:44:43"
  },
  {
    "title": "donde ponerte flores",
    "mainImage": "010.jpg",
    "link": "donde-ponerte-flores",
    "pubDate": "Mon, 11 Aug 2003 14:25:58 +0000",
    "creator": "admin",
    "teaser":"“donde ponerte flores” es el relato, en primera persona, de mujeres guatemaltecas que, desde hace dos décadas, buscan a sus esposos desaparecidos durante la guerra de 36 años, que vivió el país. un día llegaron los soldados, se los llevaron y nunca más supieron de ellos. quedaron solas, con hijos pequeños. sufrieron la desconfianza de los vecinos y las amenazas de los militares.",
    "content": `<p>“donde ponerte flores” es el relato, en primera persona, de mujeres guatemaltecas que, desde hace dos décadas, buscan a sus esposos desaparecidos durante la guerra de 36 años, que vivió el país. un día llegaron los soldados, se los llevaron y nunca más supieron de ellos. quedaron solas, con hijos pequeños. sufrieron la desconfianza de los vecinos y las amenazas de los militares. enmudecieron de miedo y no se atrevieron ni a preguntar, porque preguntar era denunciarse. les tocó hacer el doble papel de madres y de padres. solas. ha pasado el tiempo y han empezado a perder el miedo, han empezado a preguntar. y a buscar.</p>
    <p>lucrecia acaba de hallar los restos de su esposo y confiesa que ahora si se siente triste porque siempre había tenido “la esperanza de que se hubiera ido con otra”. ha reconocido las ropas de su esposo y, por fin, sabe donde podrá ponerle flores.</p>
    <img src="/images/000.jpg" alt="" class="wp-image-44"/>
    <p>saber donde están los muertos queridos puede ser importante para todos, pero lo es de una forma especial para los indígenas, quienes mantienen una estrecha relación con sus difuntos.<br>buscar los restos del ser querido, encontrarle y darle una digna sepultura es el deseo de miles de mujeres guatemaltecas.</p>
    <p><strong>director:</strong><br>lluís crous<br><strong>cámara:</strong><br>ernesto villalobos<br><strong>editor:</strong><br>david toledano<br><strong>ayudante de dirección:</strong><br>aleyda valdez<br><strong>duración</strong>: 48'<br>una producción sensemayá 2003</p>`,
    "video": "https://www.youtube.com/watch?v=eK7dJvwF3RM",
    "video-id": "eK7dJvwF3RM",
    "post_id": 42,
    "post_date": "2003-08-11 14:25:58"
  },
  {
    "title": "lamento por nahamán carmona",
    "mainImage": "00-1.jpg",
    "link": "lamento-por-nahaman-carmona",
    "pubDate": "Tue, 27 Aug 2002 14:01:05 +0000",
    "creator": "admin",
    "teaser":"nahamán carmona nació en un suburbio de san salvador. su padre lo secuestró, junto a sus dos hermanos, ruth e israel, y los llevó hasta guatemala. allí los puso a trabajar como limpiabotas. los malos tratos que recibían del padre obligaron a los tres hermanos a dejar el hogar y “agarrar” la calle. nahamán sobrevivió sin hogar desde los siete hasta los trece años.",
    "content": `<p>nahamán carmona nació en un suburbio de san salvador. su padre lo secuestró, junto a sus dos hermanos, ruth e israel, y los llevó hasta guatemala. allí los puso a trabajar como limpiabotas.</p>\n<!-- /wp:paragraph -->\n\n<!-- wp:paragraph -->\n<p>los malos tratos que recibían del padre obligaron a los tres hermanos a dejar el hogar y “agarrar” la calle. nahamán sobrevivió sin hogar desde los siete hasta los trece años. una noche, mientras dormía junto a otros compañeros en la entrada de una tienda de electrodomésticos de la capital guatemalteca, le sorprendieron cuatro policías. los agentes del orden le acusaron de “huelepega” y le golpearon salvajemente para darle una lección . fruto de la paliza, nahamán entró en la uci con golpes en el 60 por ciento de su cuerpo. moría a los diez días.</p>
    <img src="/images/02.jpg" alt="" class="wp-image-72"/>
    <p>los agentes se dieron a la fuga, pero finalmente pudieron ser detenidos, y condenados. un hecho sin precedentes en guatemala. "lamento por nahamán carmona" hace una semblanza de cómo fue nahamán y cómo hubiera podido ser si aquellos policías no le hubieran matado. los miembros de la familia disgregada de nahamán y sus amigos son quienes ponen la voz a este lamento.</p>
    <img src="/images/04.jpg" alt="" class="wp-image-73"/>
    <p><strong>director:</strong><br>lluís crous<br><strong>camara:</strong><br>ernesto villalobos<br><strong>editor:</strong><br>fàleg pagès<br><strong>duración</strong>: 49'<br>una producción sensemayá</p>`,
    "video": "https://www.youtube.com/watch?v=OOixAjrtPCc",
    "video-id": "OOixAjrtPCc",
    "post_id": 69,
    "post_date": "2002-08-27 14:01:05"
  },
  {
    "title": "el salvador, por los suelos",
    "mainImage": "s00-1.jpg",
    "link": "el-salvador-por-los-suelos",
    "pubDate": "Mon, 27 Aug 2001 14:06:34 +0000",
    "creator": "admin",
    "teaser":"en enero y febrero de 2001, el salvador sufrió dos terremotos y más de cuatro mil réplicas. el país quedó por los suelos física y anímicamente.el balance fue de 1259 fallecidos. 1.616.782 personas sin hogar, 1605 escuelas destruidas y pérdidas cercanas a los 2 mil millones de euros. el 75 por ciento del presupuesto anual del país.",
    "content": `<p>en enero y febrero de 2001, el salvador sufrió dos terremotos y más de cuatro mil réplicas. el país quedó por los suelos física y anímicamente.<br>el balance fue de 1259 fallecidos. 1.616.782 personas sin hogar, 1605 escuelas destruidas y pérdidas cercanas a los 2 mil millones de euros. el 75 por ciento del presupuesto anual del país.<br>pero las heridas más profundas no fueron económicas: el paisaje se trasformó y el estado anímico de los salvadoreños se vio seriamente afectado.</p>
    <img src="/images/s02.jpg" alt="" class="wp-image-76"/>
    <p>para realizar el documental recorrimos el país de norte a sur y de este a oeste. recogimos los testimonios de pescadores, que se quedaron sin trabajo porque el pescado había desaparecido de sus costas; de quienes viven cerca de los volcanes y están atemorizados porque desde las montañas siguen llegando amenazantes ruidos; de quienes trabajan en el interior del país y ahora cultivan campos agrietados; y de quienes han perdido a sus seres queridos, su casa, su tierra y su paisaje. de nuevo la naturaleza interrumpe, de forma macabra, la vida de los salvadoreños. no hay otra salida: sobreponerse y volver a empezar. otra vez.</p>
    <img src="/images/s04.jpg" alt="" class="wp-image-77"/>
    <p><strong>director:</strong><br>lluís crous<br><strong>cámara:</strong><br>ernesto villalobos<br><strong>editor:</strong><br>fàleg pagès<br><strong>duración</strong>: 48'<br>una producción sensemayá</p>`,
    "video": "https://www.youtube.com/watch?v=G5NkHNogVXQ",
    "video-id": "G5NkHNogVXQ",
    "post_id": 75,
    "post_date": "2001-08-27 14:06:34"
  },
  {
    "title": "la calma del huracán",
    "mainImage": "h00.jpg",
    "link": "la-calma-del-huracan",
    "pubDate": "Sun, 27 Aug 2000 14:11:01 +0000",
    "creator": "admin",
    "teaser":"en octubre de 1998 el huracán mitch arrasó los países de américa central. la magnitud del desastre provocó la solidaridad del mundo entero. ha pasado el tiempo y los supervivientes no acaban de rehacer sus vidas. nos lo cuentan cuatro familias, una de cada uno de los países más afectados.",
    "content": `<p>en octubre de 1998 el huracán mitch arrasó los países de américa central. la magnitud del desastre provocó la solidaridad del mundo entero. ha pasado el tiempo y los supervivientes no acaban de rehacer sus vidas. nos lo cuentan cuatro familias, una de cada uno de los países más afectados.<br>el miedo a morir centra la vida de alejandra y alonso, que fueron arrastrados por el lodo del volcán casitas, en nicaragua. perdieron más de veinte familiares directos: padres, hermanos, sobrinos y tres de sus cinco hijos.</p>
    <img src="/images/h01.jpg" alt="" class="wp-image-81"/>
    <p>la familia de octaviano sufrió el más profundo de los olvidos en un extremo de la pirrayita, una isla en la costa salvadoreña. la isla quedó partida en dos al juntarse las aguas de la lluvia con las del mar. y dentro del mar desaparecieron los peces, que eran su sustento.<br>en guatemala, el mitch afectó la zona bananera, aumentando así la crisis que vive el sector. gloria alicia, madre soltera, optó por quedarse. a pesar de todo.</p>
    <p>el río choluteca, que atraviesa honduras, inundó todo el país, a su paso por marcovia arrasó varias comunidades. wilmer perdió a sus abuelos y el paisaje de sus juegos infantiles.</p>
    <p><strong>director:</strong><br>lluís crous<br><strong>cámara:</strong><br>ernesto villalobos<br><strong>editora:</strong><br>àngels tous<br><strong>duración</strong>: 47'<br>una producción sensemayá</p>`,
    "video": "https://www.youtube.com/watch?v=K_1YSLOk6J0",
    "video-id": "K_1YSLOk6J0",
    "post_id": 80,
    "post_date": "2000-08-27 14:11:01"
  },
  {
    "title": "mujeres, indígenas y diputadas",
    "mainImage": "m00.jpg",
    "link": "mujeres-indigenas-y-diputadas",
    "pubDate": "Fri, 27 Aug 1999 14:33:08 +0000",
    "creator": "admin",
    "teaser":"guatemala es el país de américa central con mayor número de población indígena, el 70 por ciento de sus ciudadanos. a pesar de ello, ningún indígena ocupa un cargo entre las autoridades militares, ni en la judicatura, ni en los distintos poderes de la administración del país. “mujeres, indígenas y diputadas” relata, a través de tres mujeres indígenas que han llegado al parlamento guatemalteco, cómo afrontan sus pueblos los retos.",
    "content": `<p>guatemala es el país de américa central con mayor número de población indígena, el 70 por ciento de sus ciudadanos. a pesar de ello, ningún indígena ocupa un cargo entre las autoridades militares, ni en la judicatura, ni en los distintos poderes de la administración del país.</p>
    <img src="/images/m00.jpg" alt="" class="wp-image-85"/>
    <p>"mujeres, indígenas y diputadas" relata, a través de tres mujeres indígenas que han llegado al parlamento guatemalteco, cómo afrontan sus pueblos los retos que deben enfrentar en las nuevas democracias latinoamericanas.<br>se inicia un proceso de participación política de los indígenas en américa latina que puede cambiar el rumbo de la historia y acabar con siglos de marginación.</p>
    <img src="/images/m04.jpg" alt="" class="wp-image-86"/>
    <p>¿cómo debe ser esta participación? las democracias, tal y como las entendemos los ladinos, los no indígenas, ¿son el mejor marco para la participación política de los indígenas?. manuela alvarado, aura marina otzoy y rosalina tuyuc son las tres "mujeres, indígenas y diputadas" que, como dice en este documento la premio nóbel de la paz, rigoberta menchú, se han lanzado al mar -o sea, al parlamento- y no les va a quedar más remedio que nadar.</p>
    <p><strong>director:</strong><br>lluís crous<br><strong>càmara:</strong><br>ernesto villalobos<br><strong>editores:</strong><br>antonio cortés / marta barbal<br><strong>duración</strong>: 47'<br>una producción sensemayá</p>`,
    "video": "https://www.youtube.com/watch?v=6QE9z2CwmcA",
    "video-id": "6QE9z2CwmcA",
    "post_id": 84,
    "post_date": "1999-08-27 14:33:08"
  },
  {
    "title": "mudanzas en bosnia",
    "mainImage": "b00.jpg",
    "link": "mudanzas-en-bosnia",
    "pubDate": "Thu, 27 Aug 1998 14:39:26 +0000",
    "creator": "admin",
    "teaser":"ya casi no se habla de bosnia. el país vive sobre las cenizas de una de las guerras más crueles del siglo veinte. una guerra que no solo acarreó muertes y destrucción. el odio se apoderó de cuantos se enfrentaron. “nosotros ya no lo vamos a solucionar. si tienen suerte, serán nuestros hijos quienes superen el odio que hoy sentimos”, dice una de las participantes en “mudanzas en bosnia”",
    "content": `<p>ya casi no se habla de bosnia. el país vive sobre las cenizas de una de las guerras más crueles del siglo veinte. una guerra que no solo acarreó muertes y destrucción. el odio se apoderó de cuantos se enfrentaron.</p>
    <img src="/images/b02.jpg" alt="" class="wp-image-90"/>
    <p>"nosotros ya no lo vamos a solucionar. si tienen suerte, serán nuestros hijos quienes superen el odio que hoy sentimos", dice una de las participantes en "mudanzas en bosnia". millones de personas de los tres grupos de población existentes en el país, musulmanes, croatas y serbios, que hasta entonces habían convivido en paz, se vieron obligadas a abandonar sus hogares y a buscar refugio en zonas dominadas por los suyos.<br>los acuerdos de dayton hicieron posible una frágil paz tutelada por fuerzas internacionales, que impidieron que los enfrentamientos resurjan.<br>llegó la hora de que bosnio-musulmanes, bosnio-croatas y bosnio-serbios volvieran a sus hogares y que intentaran convivir de nuevo. o, al menos, aceptar ser vecinos.</p>
    <img src="/images/b01.jpg" alt="" class="wp-image-91"/>
    <p>“mudanzas en bosnia” se adentra a las tres zonas en las que hoy está partido el país; cruzando unas fronteras interiores (“allí, donde está aquel palo, empieza la zona serbia”), que dividen y alejan más aún a unos de otros. ha llegado el tiempo de la mudanza.</p>
    <p><strong>director:</strong><br>lluís crous<br><strong>càmaras:</strong><br>camilo zamora/ josep maría castellá<br><strong>editora:</strong><br>anna roca<br><strong>duración</strong>: 50'<br>una producción sensemayá</p>`,
    "video": "https://www.youtube.com/watch?v=boQcbVnG_VM",
    "video-id": "boQcbVnG_VM",
    "post_id": 89,
    "post_date": "1998-08-27 14:39:26"
  },
  {
    "title": "siete sueños de paz",
    "mainImage": "100.jpg",
    "link": "siete-suenos-de-paz/",
    "pubDate": "Sun, 27 Aug 1995 14:44:28 +0000",
    "creator": "admin",
    "teaser":"en 1992, después de 12 años de guerra civil, se firmó la paz en el salvador. pero ¿qué significó para los salvadoreños? en “siete sueños de paz”, siete salvadoreños comparten sus experiencias y sus esperanzas en el futuro.y sueñan. sueñan hasta donde pueden. son siete sueños, representativos de otros tantos sueños de los salvadoreños.",
    "content": `<p>en 1992, después de 12 años de guerra civil, se firmó la paz en el salvador. pero ¿qué significó para los salvadoreños? en “siete sueños de paz”, siete salvadoreños comparten sus experiencias y sus esperanzas en el futuro.<br>y sueñan. sueñan hasta donde pueden.</p>
    <img src="/images/101.jpg" alt="" class=""/>
    <p>son siete sueños, representativos de otros tantos sueños de los salvadoreños. ansias de un futuro mejor. y en paz.<br>juan es un actor del grupo de teatro "sol del río". fue locutor de la radio guerrillera farabundo martí.<br>guadalupe perdió a sus padres, a su esposo y a uno de sus hijos en la guerra. hoy ha podido levantar una casita junto a la vía del tren. ángel, un guerrillero del fmln, y nicolás, policía durante la guerra, en la actualidad son miembros de la policía nacional civil y, además, grandes amigos.<br>miguel es un niño vendedor de periódicos para el que la máxima preocupación es ”darle duro al balón” . pero “duro, duro”.</p>
    <img src="/images/103.jpg" alt="" class=""/>
    <p>vitelio fue capturado por el ejército y separado de sus padres durante la guerra. hoy es miembro de la pandilla “mara 18”. francisco es un cobrador de autobús, que se juega la vida por las caóticas calles de san salvador. y tiene un sueño claro: llegar a ser artista en “jólibu”</p>
    <p><strong>director:</strong><br>lluís crous<br><strong>càmara:</strong><br>enrique zúñiga<br><strong>editor:</strong><br>wilmer franco<br><strong>duración</strong>: 49'<br>una producción sensemayá</p>`,
    "video": "https://www.youtube.com/watch?v=FajdcTUMXmM",
    "video-id": "FajdcTUMXmM",
    "post_id": 94,
    "post_date": "1995-08-27 14:44:28"
  }
]